import store from "@/store";
import { isObject } from "is-what";
import { createRouter, createWebHistory } from "vue-router";
import { domTitle, setDocumentTitle } from "@/utils/domUtil";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { notRequireAuth: true },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { notRequireAuth: true },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/union-login",
    name: "UnionLogin",
    meta: { notRequireAuth: true },
    component: () => import("../views/UnionLogin.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: { notRequireAuth: true },
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    meta: { notRequireAuth: true },
    component: () => import("../views/Forbidden.vue"),
  },
  {
    path: "/agreement",
    name: "Agreement",
    meta: { notRequireAuth: true },
    component: () => import("../views/Agreement.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: { notRequireAuth: true },
    component: () => import("../views/Privacy.vue"),
  },
  {
    // @link https://stackoverflow.com/questions/63526486/vue-router-catch-all-wildcard-not-working
    // path: '*', // 直接这样写会有一个报错
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: { notRequireAuth: true },
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 设置页头标题等
setDocumentTitle(domTitle);

// 路由保镖, 受保护页面需鉴权后才能访问
router.beforeEach((to, from, next) => {
  // 这部分判断登录和代理的代码必须在循环内部
  // 注: window.localStorage 操作是同步的
  const currentUser = store.state.auth.currentUser
    ? store.state.auth.currentUser
    : JSON.parse(window.localStorage.getItem("currentUser"));
  const currentAgent = store.state.auth.agentInfo
    ? store.state.auth.agentInfo
    : JSON.parse(window.localStorage.getItem("agentInfo"));
  const isLogin = isObject(currentUser) && Object.keys(currentUser).length > 0;
  console.log("来到路由了")

  // 已登录的情况下, 不允许进入登录页面
  if (isLogin && to.name == "Login") {
    next({ name: "Home" });
    return;
  }
  console.log("来到路由了____111111111111111111")
  if (
    isLogin &&
    currentUser.is_agent &&
    !currentAgent &&
    to.name != "Forbidden"
  ) {
    next({
      name: "Forbidden",
      query: { msg: "agent not matched" },
    });
    return;
  }
  console.log("来到路由了___22222222222222222")

  // if (to.meta.notRequireAuth) { // 单路由判断
  // 通过 to.matched.some() 来判断所有路由, 包含其子路由
  // 约定: 未定义 notRequireAuth 或 notRequireAuth != true 则表示需要授权后才可访问
  if (to.matched.some((record) => !record.meta.notRequireAuth)) {
    if (isLogin) {
      console.log("来到路由了___3333333333333333")
      // requireAgentLevel 含义: 0 必须是代理或分润用户才能访问, 1 一级代理可访问, 2 二级代理可访问, 未设置表示普通用户可访问
      const r = [...to.matched].pop(); // 最后一个匹配的路由是当前访问的页面
      if (
        r.meta.requireAgentLevel == 0 &&
        !currentAgent &&
        !currentUser.is_profit
      ) {
        next({
          name: "Forbidden",
          query: {
            ref: encodeURIComponent(to.fullPath),
          },
        });
        return;
      }
      if (
        r.meta.requireAgentLevel > 0 &&
        (!currentAgent || r.meta.requireAgentLevel != currentAgent.level)
      ) {
        next({
          name: "Forbidden",
          query: {
            ref: encodeURIComponent(to.fullPath),
          },
        });
        return;
      }
      next();
    } else if (to.name != "Login") {
      console.log("来到路由了___444444444444")
      next({
        name: "Login",
        query: {
          redirect: encodeURIComponent(to.fullPath),
        },
      });
    }
    return;
  }

  next();
});

export default router;
